import Base from 'Components/Diagnose/Template/Base';
import {
  dangerIndicator,
  diagnosingIndicator,
  successIndicator,
  warningIndicator,
} from 'Components/Diagnose/Template/States';
import React, { useEffect, useState } from 'react';
import Sleep from 'Utils/Sleep';
import CellularNetworkComponentAction from 'Components/Actions/CellularNetworkComponentAction';

export default function CellularNetworkComponent(props) {
  const icon = 'signal-cellular-2';
  const description = 'Şebeke Testi';
  const [mountChild, setMountChild] = useState(true);
  const [killYourself, setKillYourself] = useState(false);
  const [stateText, setStateText] = useState(warningIndicator.textTemplate);
  const [stateIndicator, setStateIndicatior] = useState(warningIndicator.indicatorTemplate);
  const [iconBackgroundColor, setIconBackgroundColor] = useState('warning');
  const [iconForegroundColor, setIconForegroundColor] = useState('warning');
  const [elevanimate, setElevanimate] = useState('primary');

  const [actionComponent, setActionComponent] = useState(null);

  useEffect(async () => {
    if (props.startDiagnosis === true) {
      setStateText(diagnosingIndicator.textTemplate);
      setStateIndicatior(diagnosingIndicator.indicatorTemplate);
      setIconBackgroundColor('primary');
      setIconForegroundColor('primary');
      setElevanimate('primary');
      await Sleep(500);

      setActionComponent(<CellularNetworkComponentAction endTest={endTest} />);
    }
  }, [props.startDiagnosis]);

  function endTest(result, state) {
    setMountChild(false);

    if (result === true && state === 'PASSED') {
      setStateText(successIndicator.textTemplate);
      setStateIndicatior(successIndicator.indicatorTemplate);
      setIconBackgroundColor('success');
      setIconForegroundColor('success');
      setElevanimate('success');
    } else if (result === false && (state === 'FAILED' || state === 'SKIPPED')) {
      setStateText(dangerIndicator.textTemplate);
      setStateIndicatior(dangerIndicator.indicatorTemplate);
      setIconBackgroundColor('danger');
      setIconForegroundColor('danger');
      setElevanimate('danger');
    }

    props.startAfter(true);
  }

  return (
    <>
      {mountChild && actionComponent}
      {!killYourself
            && (
            <Base
              icon={icon}
              description={description}
              stateText={stateText}
              stateIndicator={stateIndicator}
              elevanimate={elevanimate}
              iconBackgroundColor={iconBackgroundColor}
              iconForegroundColor={iconForegroundColor}
            />
            )}
    </>
  );
}
