import React from 'react';
import Ikon from 'Components/Ikon/Ikon';
import { warningIndicator } from './States';

function Base({
  iconBackgroundColor = 'warning',
  iconForegroundColor = 'warning',
  icon,
  iconWidth = 35,
  iconHeight = 35,
  description,
  stateText = warningIndicator.textTemplate,
  stateIndicator = warningIndicator.indicatorTemplate,
  elevanimate = 'primary',
}) {
  const iconBackgroundClassess = `icon-part radius-11 bg-${iconBackgroundColor}`;
  const iconForegroundClasses = `fill-${iconForegroundColor}`;
  const baseContainerClasses = `diagnose-item mt-20 elevanimate-${elevanimate}`;
  return (
    <div className={baseContainerClasses}>
      <div className={iconBackgroundClassess}>
        <Ikon icon={icon} className={iconForegroundClasses} width={iconWidth} height={iconHeight} />
      </div>
      <div className="text-part">
        <h5 className="h5-bold color-secondary">{description}</h5>
        {stateText}
      </div>
      <div className="indicator-part">
        {stateIndicator}
      </div>
    </div>
  );
}

export default Base;
