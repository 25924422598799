import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from '../../Utils/Sleep';

function BluetoothComponentAction(props) {
  const [showDialog, setShowDialog] = useState(false);

  const { bluetooth } = navigator;

  useEffect(() => {
    bluetoothAvailibility();
  }, []);

  async function bluetoothAvailibility() {
    if (showDialog === true) {
      setShowDialog(false);
      await Sleep(2000);
    }

    navigator.bluetooth.getAvailability().then((available) => {
      if (available) { endTest(true, 'PASSED', {}); } else { setShowDialog(true); }
    });
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('BluetoothComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'FAILED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => bluetoothAvailibility()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrarla
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="bluetooth-off"
      >
        <p>Cihazınızın Bluetooth özelliği kapalı görünüyor. Bluetoothu aktif edip Tekrarla butonuna basınız</p>
      </Dialog>
    </>
  );
}

export default BluetoothComponentAction;
