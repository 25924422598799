async function Post(url = '', payload = {}, requestType = 'GET') {
  const formBody = [];
  let body = null;
  for (const key of Object.keys(payload)) {
    if (key === 'length' || !payload.hasOwnProperty(key)) continue;
    formBody.push(`${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`);
  }
  body = formBody.join('&');
  return await fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',

    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body,
  }).then((r) => {
  }).catch((err) => {
  });
}

export default Post;
