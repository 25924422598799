import React, {useEffect, useState} from 'react';
import DeadPixelComponent from 'Components/Diagnose/DeadPixelComponent';
import ScreenTouchComponent from 'Components/Diagnose/ScreenTouchComponent';
import ScreenBreakComponent from 'Components/Diagnose/ScreenBreakComponent';
import WifiCheckComponent from 'Components/Diagnose/WifiCheckComponent';
import CellularNetworkComponent from 'Components/Diagnose/CellularNetworkComponent';
import BluetoothComponent from 'Components/Diagnose/BluetoothComponent';
import GpsCheckComponent from 'Components/Diagnose/GpsCheckComponent';
import CameraFrontComponent from 'Components/Diagnose/CameraFrontComponent';
import CameraBackComponent from 'Components/Diagnose/CameraBackComponent';
import BatteryChargeCheckComponent from 'Components/Diagnose/BatteryChargeCheckComponent';
import SpeakerCheckComponent from 'Components/Diagnose/SpeakerCheckComponent';
import MicrophoneCheckComponent from 'Components/Diagnose/MicrophoneCheckComponent';
import VibrationCheckComponent from 'Components/Diagnose/VibrationCheckComponent';
import {animateScroll as scroll, Events, scrollSpy} from 'react-scroll';
import AccelerometerCheckComponent from '../../Components/Diagnose/AccelerometerCheckComponent';
import GyroscopeCheckComponent from '../../Components/Diagnose/GyroscopeCheckComponent';
import GravitySensorCheckComponent from '../../Components/Diagnose/GravitySensorCheckComponent';

function ComponentList(props) {
  const [screenTouchComponentStart, setScreenTouchComponentStart] = useState(false);
  const [screenBreakComponentStart, setScreenBreakComponentStart] = useState(false);
  const [wifiCheckComponentStart, setWifiCheckComponentStart] = useState(false);
  const [cellularNetworkComponentStart, setCellularNetworkComponentStart] = useState(false);
  const [bluetoothComponentStart, setBluetoothComponentStart] = useState(false);
  const [gpsCheckComponentStart, setGpsCheckComponentStart] = useState(false);
  const [cameraFrontComponentStart, setCameraFrontComponentStart] = useState(false);
  const [cameraBackComponentStart, setCameraBackComponentStart] = useState(false);
  const [batteryHealthCheckComponentStart, setBatteryHealthCheckComponentStart] = useState(false);
  const [batteryChargeCheckComponentStart, setBatteryChargeCheckComponentStart] = useState(false);
  const [speakerCheckComponentStart, setSpeakerCheckComponentStart] = useState(false);
  const [headphoneCheckComponentStart, setHeadphoneCheckComponentStart] = useState(false);
  const [microphoneCheckComponentStart, setMicrophoneCheckComponentStart] = useState(false);
  const [accelerometerCheckComponentStart, setAccelerometerCheckComponentStart] = useState(false);
  const [gyroscopeCheckComponentStart, setGyroscopeCheckComponentStart] = useState(false);
  const [gravitySensorCheckComponentStart, setGravitySensorCheckComponentStart] = useState(false);
  const [vibrationCheckComponentStart, setVibrationCheckComponentStart] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register('begin', (to, element) => {
    });

    Events.scrollEvent.register('end', (to, element) => {
    });

    scrollSpy.update();
  });

  function scrollex() {
    scroll.scrollTo(600);
  }

  function complete() {
    props.finishDiagnosis();
  }

  return (
      <>
        <DeadPixelComponent startDiagnosis={props.startDiagnosis} startAfter={setScreenTouchComponentStart}/>
        <ScreenTouchComponent startDiagnosis={screenTouchComponentStart} startAfter={setScreenBreakComponentStart}/>
        <ScreenBreakComponent startDiagnosis={screenBreakComponentStart} startAfter={setWifiCheckComponentStart}/>
        <WifiCheckComponent startDiagnosis={wifiCheckComponentStart} startAfter={setCellularNetworkComponentStart}/>
        <CellularNetworkComponent
            startDiagnosis={cellularNetworkComponentStart}
            startAfter={setBluetoothComponentStart}
        />
        <BluetoothComponent startDiagnosis={bluetoothComponentStart} startAfter={setGpsCheckComponentStart}/>
        <GpsCheckComponent
            scrollex={scrollex}
            startDiagnosis={gpsCheckComponentStart}
            startAfter={setCameraFrontComponentStart}
        />
        <CameraFrontComponent startDiagnosis={cameraFrontComponentStart} startAfter={setCameraBackComponentStart}/>
        <CameraBackComponent
            startDiagnosis={cameraBackComponentStart}
            startAfter={setBatteryChargeCheckComponentStart}
        />

        <BatteryChargeCheckComponent
            startDiagnosis={batteryChargeCheckComponentStart}
            startAfter={setSpeakerCheckComponentStart}
        />

        <SpeakerCheckComponent
            startDiagnosis={speakerCheckComponentStart}
            startAfter={setMicrophoneCheckComponentStart}
        />
        <MicrophoneCheckComponent
            startDiagnosis={microphoneCheckComponentStart}
            startAfter={setAccelerometerCheckComponentStart}
        />

        <AccelerometerCheckComponent startDiagnosis={accelerometerCheckComponentStart}
                                     startAfter={setGyroscopeCheckComponentStart}/>
        <GyroscopeCheckComponent startDiagnosis={gyroscopeCheckComponentStart}
                                 startAfter={setGravitySensorCheckComponentStart}/>
        <GravitySensorCheckComponent startDiagnosis={gravitySensorCheckComponentStart}
                                     startAfter={setVibrationCheckComponentStart}/>

        <VibrationCheckComponent startDiagnosis={vibrationCheckComponentStart} startAfter={complete}/>
      </>
  );
}

export default ComponentList;
