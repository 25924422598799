import React, { useEffect, useRef, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Sleep from '../../Utils/Sleep';
import Dialog from '../Modal/Dialog';
import Modal from '../Modal/Modal';

export default function IOSMicrophoneCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [permissionGrant, setPermissionGrant] = useState('undefined');
  const [mediaStream, setMediaStream] = useState(undefined);
  const videoRef = useRef(null);

  const [message, setMessage] = useState();

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  useEffect(async () => {

    await Sleep(2000).then(r => {
      endTest(true, 'PASSED');
    })


    maybeGranted();
    if (permissionGrant === 'granted') {
      const stream = await startStream();
      const mediaRecorder = new MediaRecorder(stream);
      setMessage(mediaRecorder.state, mediaRecorder.audioBitsPerSecond);

      await streamToView(stream);
    } else if (permissionGrant !== 'initial') {
      setShowDialog(true);
    }
  }, [permissionGrant]);

  async function maybeGranted() {
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        facingMode: 'user',
      },
    }).then((stra) => {
      if (stra) {
        setPermissionGrant('granted');
      } else {
        setPermissionGrant('denied');
      }
    }).catch((err) => {
      setPermissionGrant('denied');
    });
  }

  async function startStream() {
    setShowDialog(false);
    return await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: 'user',
      },
    }).then((stream) => {
      setMediaStream(stream);

      return stream;
    }).catch((err) => {

    });
  }

  async function streamToView() {
    if (mediaStream) {
      setShowDialog(false);
      setShowModal(true);
      videoRef.current.play();
      await maybeSleep(2000).then((r) => {
        mediaStream.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop();
            setShowModal(false);
            videoRef.current.srcObject = null;
            endTest(true, 'PASSED');
          } else {
            setShowModal(false);
            endTest(true, 'PASSED');
          }
        });

        setShowModal(false);
        endTest(true, 'PASSED');
      });
    }
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('MicrophoneCheckComponentResult', payload);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={streamToView}
      className="f-btn f-btn-primary f-pull-right"
    >
      Devam Et
    </button>
  );

  return (
    <>
      <Modal customClass="opacity0" init={showModal}>
        <div className="full-width">
          <video style={{ display: 'none' }} ref={videoRef} onCanPlay={streamToView} autoPlay playsInline muted />

        </div>

      </Modal>
      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="microphone-off"
      >
        <p>
          Mikrofonunuzu test edebilmemiz için izin vermelisiniz. İzni verdikten sonra Devam et butonuna
          tıklayınız.
        </p>
        {message}
      </Dialog>

    </>
  );
}
