import React, { useEffect, useState } from 'react';
import DeadPixelComponent from 'Components/Diagnose/DeadPixelComponent';
import ScreenTouchComponent from 'Components/Diagnose/ScreenTouchComponent';
import ScreenBreakComponent from 'Components/Diagnose/ScreenBreakComponent';
import GpsCheckComponent from 'Components/Diagnose/GpsCheckComponent';
import IOSSpeakerCheckComponent from 'Components/Diagnose/IOSSpeakerCheckComponent';
import { animateScroll as scroll, Events, scrollSpy } from 'react-scroll';
import IOSBackCameraComponent from '../../Components/Diagnose/IOSBackCameraComponent';
import IOSFrontCameraComponent from '../../Components/Diagnose/IOSFrontCameraComponent';
import IOSWifiCheckComponent from '../../Components/Diagnose/IOSWifiCheckComponent';
import IOSCellularNetworkComponent from '../../Components/Diagnose/IOSCellularNetworkComponent';
import IOSMicrophoneCheckComponent from '../../Components/Diagnose/IOSMicrophoneCheckComponent';
import IOSHeadphoneCheckComponent from '../../Components/Diagnose/IOSHeadphoneCheckComponent';

function ComponentListIOS(props) {
  const [screenTouchComponentStart, setScreenTouchComponentStart] = useState(false);
  const [screenBreakComponentStart, setScreenBreakComponentStart] = useState(false);
  const [wifiCheckComponentStart, setWifiCheckComponentStart] = useState(false);
  const [cellularNetworkComponentStart, setCellularNetworkComponentStart] = useState(false);
  const [gpsCheckComponentStart, setGpsCheckComponentStart] = useState(false);
  const [cameraFrontComponentStart, setCameraFrontComponentStart] = useState(false);
  const [cameraBackComponentStart, setCameraBackComponentStart] = useState(false);
  const [speakerCheckComponentStart, setSpeakerCheckComponentStart] = useState(false);
  const [headphoneCheckComponentStart, setHeadphoneCheckComponentStart] = useState(false);
  const [microphoneCheckComponentStart, setMicrophoneCheckComponentStart] = useState(false);
  const [vibrationCheckComponentStart, setVibrationCheckComponentStart] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register('begin', (to, element) => {
    });

    Events.scrollEvent.register('end', (to, element) => {
    });

    scrollSpy.update();
  });

  function scrollex() {
    scroll.scrollTo(600);
  }

  function setWhichNetworkComponentStart() {
    if (props.connectionType === 'mobile') {
      setWifiCheckComponentStart(true);
    }

    if (props.connectionType === 'wifi') {
      setCellularNetworkComponentStart(true);
    }
  }

  function complete() {
    props.finishDiagnosis();
  }

  return (
    <>
      <DeadPixelComponent startDiagnosis={props.startDiagnosis} startAfter={setScreenTouchComponentStart} />
      <ScreenTouchComponent startDiagnosis={screenTouchComponentStart} startAfter={setScreenBreakComponentStart} />

      <ScreenBreakComponent startDiagnosis={screenBreakComponentStart} startAfter={setWhichNetworkComponentStart} />

      {props.connectionType === 'mobile'
            && <IOSWifiCheckComponent startDiagnosis={wifiCheckComponentStart} startAfter={setGpsCheckComponentStart} />}

      {props.connectionType === 'wifi'
            && <IOSCellularNetworkComponent startDiagnosis={cellularNetworkComponentStart} startAfter={setGpsCheckComponentStart} />}
      {
      <GpsCheckComponent
        scrollex={scrollex}
        startDiagnosis={gpsCheckComponentStart}
        startAfter={setCameraFrontComponentStart}
      />
        }

      <IOSFrontCameraComponent startDiagnosis={cameraFrontComponentStart} startAfter={setCameraBackComponentStart} />
      <IOSBackCameraComponent
        startDiagnosis={cameraBackComponentStart}
        startAfter={setSpeakerCheckComponentStart}
      />
      <IOSSpeakerCheckComponent
        startDiagnosis={speakerCheckComponentStart}
        startAfter={setMicrophoneCheckComponentStart}
      />

      <IOSMicrophoneCheckComponent
        startDiagnosis={microphoneCheckComponentStart}
        startAfter={complete}
      />
    </>
  );
}

export default ComponentListIOS;
