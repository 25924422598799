import React, { useEffect, useState } from 'react';

function DiagnosisEmptySplash(props) {

  return (
    <div className="full-width bg-warning-light center"  >
      <div>
        <p className="color-warning screen-touch-action-text font-size-20 ">
          {' '}
         Hazırlanıyor...
        </p>
      </div>

    </div>
  );
}

export default DiagnosisEmptySplash;
