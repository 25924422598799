import React from 'react';
import ComponentListIOS from './ComponentListIOS';

function DiagnoseScreenIOS(props) {
  return (
    <>
      <div className="app-header">
        {props.showDiagnosisButton

                && (
                <div className="diagnosis-button-container">
                  <button
                    onClick={() => props.handleStartDiagnosisButton()}
                    className="f-btn f-btn-info"
                  >
                    Başlat
                  </button>
                </div>
                )}

        {props.showLoadingIndicator
                && <div className="spinx center app-indicator" />}
      </div>
      <div className="diagnose-items" id="diagnose-items">
        <ComponentListIOS connectionType={props.connectionType} startDiagnosis={props.startDiagnosis} finishDiagnosis={props.finishDiagnosis} />
      </div>
    </>
  );
}

export default DiagnoseScreenIOS;
