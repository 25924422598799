import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Modal from 'Components/Modal/Modal';
import Dialog from 'Components/Modal/Dialog';

const colors = ['0001fb', 'fffe03', 'ffffff', '000000'];

function DeadPixelComponentAction(props) {
  const containerClass = 'dead-pixel--switch-colors dead-pixel--color-';
  const [currentColor, setCurrentColor] = useState('fe0000');
  const [showModal, setShowModal] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [catchedError, setCachedError] = useState();

  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';

  function nextColor() {
    if (colors.length !== 0) {
      setCurrentColor(colors[0]);
      colors.shift();
    } else {
      promptDialog();
    }
  }

  function promptDialog() {
    setShowModal(false);
    setShowDialog(true);
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('DeadPixelComponentResult', payload);
    document.body.style.position = '';
    document.body.style.overflow = '';
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'FAILED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Evet
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => endTest(true, 'PASSED')}
      className="f-btn f-btn-primary f-pull-right"
    >
      Hayır
    </button>
  );

  return (
    <>
      <Modal init={showModal}>
        <div
          className={containerClass + currentColor}
          onClick={() => nextColor()}
        >
          <div className="center-both full-width">
            <div>
              <p>Lütfen Ekrana basınız</p>
              { catchedError }
            </div>
          </div>
        </div>
      </Modal>
      {!showModal
            && (
            <Dialog
              init={showDialog}
              negativeButton={negativeButton}
              positiveButton={positiveButton}
              backdrop={showDialog}
              icon="cellphone-screenshot"
            >
              <p>Ekranda hiç ölü piksel gördünüz mü?</p>
            </Dialog>
            )}
    </>
  );
}

export default DeadPixelComponentAction;
