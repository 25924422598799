import React, { useEffect, useState, useRef } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from 'Utils/Sleep';

import catGraphic from 'Assets/images/cat.png';
import dogGraphic from 'Assets/images/dog.png';
import birdGraphic from 'Assets/images/bird.png';

const bark = 'https://diagnostic.ultraguvence.com/bark.mp3';
const meow = 'https://diagnostic.ultraguvence.com/meow.mp3';
const chirp = 'https://diagnostic.ultraguvence.com/chirp.mp3';

const cat = {
  audio: meow,
  image: catGraphic,
};
const dog = {
  audio: bark,
  image: dogGraphic,
};

const bird = {
  audio: chirp,
  image: birdGraphic,
};
const random = Math.floor(Math.random() * 3);

function IOSHeadphoneCheckComponentAction(props) {
  const [showFirstDialog, setShowFirstDialog] = useState({
    show: true,
    message: 'Kulaklık girişinizi test edebilmemiz için lütfen kulaklığınızı cihazına takınız.',
  });
  const [showSecondDialog, setShowSecondDialog] = useState(false);
  const [chosenAnimal, setChosenAnimal] = useState();
  const [context, setAudioContext] = useState(new (window.AudioContext || window.webkitAudioContext)());
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [headphoneSet, setHeadphoneSet] = useState(false);
  const [initialMessage, setInitialMessage] = useState('Lütfen cihazınızın sesini açın ve Dinle dokunun basın. Ardından sesini duyduğunuz hayvanın görseline dokunun.');
  const [initialActionText, setInitialActionText] = useState('Dinle');

  const playButtonRef = useRef();

  useEffect(async () => {
    if (headphoneSet === true) {
      unlockAudio();
    }
  }, [headphoneSet, isUnlocked]);

  async function checkHeadset() {
    setShowFirstDialog({ show: false, message: '' });
    setShowSecondDialog(true);
    setHeadphoneSet(true);
  }

  function getAnAnimal() {
    if (random === 0) {
      setChosenAnimal(cat);
      return cat;
    } if (random === 1) {
      setChosenAnimal(dog);
      return dog;
    }
    setChosenAnimal(bird);
    return bird;
  }

  function decodeShim(arraybuffer) {
    return new Promise((resolve, reject) => {
      context.decodeAudioData(arraybuffer, (buffer) => resolve(buffer), (err) => reject(err));
    });
  }

  function unlockAudio() {
    if (isUnlocked) return;

    // Scratch buffer to prevent memory leaks on iOS.
    // See: https://stackoverflow.com/questions/24119684/web-audio-api-memory-leaks-on-mobile-platforms
    const _scratchBuffer = context.createBuffer(1, 1, 22050);

    // We call this when user interaction will allow us to unlock
    // the audio API.
    var unlock = function (e) {
      const source = context.createBufferSource();
      source.buffer = _scratchBuffer;
      source.connect(context.destination);

      // Play the empty buffer.
      source.start(0);

      // Calling resume() on a stack initiated by user gesture is
      // what actually unlocks the audio on Chrome >= 55.
      if (typeof context.resume === 'function') {
        context.resume();
      }

      // Once the source has fired the onended event, indicating it did indeed play,
      // we can know that the audio API is now unlocked.
      source.onended = function () {
        source.disconnect(0);

        // Don't bother trying to unlock the API more than once!
        setIsUnlocked(true);

        // Remove the click/touch listeners.
        playButtonRef.current.removeEventListener('touchstart', unlock, true);
        playButtonRef.current.removeEventListener('touchend', unlock, true);
        playButtonRef.current.removeEventListener('click', unlock, true);
      };
    };

    playButtonRef.current.addEventListener('touchstart', unlock, true);
    playButtonRef.current.addEventListener('touchend', unlock, true);
    playButtonRef.current.addEventListener('click', unlock, true);
  }

  async function loadAudioFile(file) {
    const _sfxFile = await fetch(file);
    const arraybuffer = await _sfxFile.arrayBuffer();
    let audiobuffer;

    try {
      audiobuffer = await context.decodeAudioData(arraybuffer);
    } catch (e) {
      // Browser wants older callback based usage of decodeAudioData
      audiobuffer = await decodeShim(arraybuffer);
    }

    return audiobuffer;
  }

  async function play() {
    const audioFile = getAnAnimal();
    return await loadAudioFile(audioFile.audio).then((audioBuffer) => {
      const sourceNode = context.createBufferSource();
      sourceNode.buffer = audioBuffer;
      sourceNode.connect(context.destination);
      sourceNode.start();
      setInitialActionText('Tekrar Dinle');

      return sourceNode;
    });
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function soundCheck(animal) {
    if (isUnlocked === true) {
      if (animal === chosenAnimal) {
        maybeSleep(1000).then((r) => {
          endTest(true, 'PASSED');
        });
      } else {
        setInitialMessage('Cevabınız yanlış. Dinle butonuna dokunarak tekrar deneyebilirsiniz.');
        setIsUnlocked(false);
      }
    } else {
      setInitialMessage('Lütfen önce Dinle butonuna basarak sesi dinleyin. Ardınan cevaba dokunun.');
    }
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('HeadphoneCheckComponentResult', payload);
    setShowSecondDialog(false);
    setShowFirstDialog({ show: false, message: '' });
    props.endTest(result, state);
  }

  const negativeButtonFirst = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButtonFirst = (
    <button
      onClick={checkHeadset}
      className="f-btn f-btn-primary f-pull-right"
    >
      Taktım
    </button>
  );

  const negativeButtonSecond = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButtonSecond = (
    <button
      onClick={play}
      className="f-btn f-btn-primary f-pull-right"
      ref={playButtonRef}
    >
      {initialActionText}
    </button>
  );
  return (
    <>
      <Dialog
        init={showFirstDialog.show}
        negativeButton={negativeButtonFirst}
        positiveButton={positiveButtonFirst}
        backdrop={showFirstDialog.show}
        icon="headphones-off"
      >
        <p>Kulaklık girişinizi test edebilmemiz için lütfen kulaklığınızı cihazına takınız.</p>

      </Dialog>

      <Dialog
        init={showSecondDialog}
        negativeButton={negativeButtonSecond}
        positiveButton={positiveButtonSecond}
        backdrop={showSecondDialog}
        icon="headphones"
      >
        <p>{initialMessage}</p>
        <p className="animalPics">
          <span>
            <img
              src={cat.image}
              alt=""
              onTouchStart={() => soundCheck(cat)}
            />
            <br />
            Kedi
          </span>
          <span>
            <img
              src={dog.image}
              alt=""
              onTouchStart={() => soundCheck(dog)}
            />
            <br />
            Köpek
          </span>

          <span>

            <img
              src={bird.image}
              alt=""
              onTouchStart={() => soundCheck(bird)}
            />
            <br />
            Kuş
          </span>
        </p>
      </Dialog>
    </>
  );
}

export default IOSHeadphoneCheckComponentAction;
