const API_URL = '/api/';

const IS_EXPIRED = (key) => `${API_URL}check-is-active/${key}`;
const DIAGNOSE_RESULT_SET = `${API_URL}diagnose-result-set`;
const ISP_DETAILS = `${API_URL}get-ip`;

export default {
  IS_EXPIRED,
  DIAGNOSE_RESULT_SET,
  ISP_DETAILS,
};
