import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Sleep from '../../Utils/Sleep';
import Dialog from '../Modal/Dialog';

export default function MicrophoneCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [permissionGrant, setPermissionGrant] = useState('initial');

  maybeGranted();

  useEffect(async () => {
    if (permissionGrant === 'granted') {
      setShowDialog(false);
      const stream = await prepareDevice();
      let mediaRecorder = await processStream(stream);
      mediaRecorder.start();
      maybeSleep(2000).then((r) => {
        mediaRecorder.stop();
        mediaRecorder = undefined;
        endTest(true, 'PASSED');
      });
    } else if (permissionGrant !== 'initial') {
      setShowDialog(true);
    }
  }, [permissionGrant]);

  async function maybeGranted() {
    navigator.mediaDevices.getUserMedia({ audio: true });

    return await navigator.permissions.query({ name: 'microphone', ...'' })
      .then((permission) => {
        setPermissionGrant(permission.state);
      });
  }

  async function prepareDevice() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return await navigator.mediaDevices.getUserMedia({ audio: true })
        .then((incomingStream) => incomingStream)
        .catch((err) => {
        });
    }
  }

  async function processStream(stream) {
    return new MediaRecorder(stream);
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('MicrophoneCheckComponentResult', payload);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => maybeGranted()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Devam Et
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="microphone-off"
      >
        <p>
          Mikrofonunuzu test edebilmemiz için izin vermelisiniz. İzni verdikten sonra Devam et butonuna
          tıklayınız.
        </p>
      </Dialog>

    </>
  );
}
