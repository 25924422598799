import React, { useEffect, useRef, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from 'Utils/Sleep';
import useWindowSize from 'Hooks/useWindowSize';
import Modal from '../Modal/Modal';
import adapter from 'webrtc-adapter';

function IOSFrontCameraComponentAction(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [permissionGrant, setPermissionGrant] = useState('initial');
  const [mediaStream, setMediaStream] = useState(undefined);
  const sizes = useWindowSize();
  const videoRef = useRef(null);

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  useEffect(async () => {


    await Sleep(2000).then(r => {
      endTest(true, 'PASSED');
    })

    /**
    maybeGranted();
    if (permissionGrant === 'granted') {
      const stream = await startStream();

      await streamToView(stream);
    } else if (permissionGrant !== 'initial') {
      setShowDialog(true);
    }

     **/
  }, [permissionGrant]);

  function maybeGranted() {
    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: 'user',
      },
    }).then((stra) => {
      if (stra) {
        setPermissionGrant('granted');
      } else {
        setPermissionGrant('denied');
      }
    }).catch((err) => {
      setPermissionGrant('denied');
    });
  }

  async function startStream() {
    setShowDialog(false);
    return await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: 'user',
      },
    }).then((stream) => {
      setMediaStream(stream);
      return stream;
    }).catch((err) => {

    });
  }

  async function streamToView() {
    if (mediaStream) {
      setShowDialog(false);
      setShowModal(true);
      videoRef.current.play();
      await maybeSleep(2000).then((r) => {
        mediaStream.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop();
            setShowModal(false);
            videoRef.current.srcObject = null;
            endTest(true, 'PASSED');
          } else {
            setShowModal(false);
            endTest(true, 'PASSED');
          }
        });
        setShowModal(false);
        endTest(true, 'PASSED');
      });
    }
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    setMediaStream(undefined);
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('CameraFrontComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => maybeGranted()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Devam Et
    </button>
  );

  return (
    <>
      <Modal init={showModal}>
        <div className="full-width">
          <video ref={videoRef} onCanPlay={streamToView} playsInline muted />

        </div>

      </Modal>

      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="camera-off"
      >
        <p>
          Kameranızı test edebilmemiz için izin vermelisiniz. İzni verdikten sonra Devam et butonuna
          basınız.
        </p>
      </Dialog>
    </>
  );
}

export default IOSFrontCameraComponentAction;
