import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import ep from 'Utils/endpoints';
import Get from "../../Utils/Get";

function IOSCellularNetworkComponentAction(props) {
  const [showDialog, setShowDialog] = useState({ show: true, message: 'Mobil şebeke ağına bağlı değilsiniz. Cihazınızın şebeke özelliğini test edebilmemiz için mobil ağlara bağlı olmalısınız. Lütfen hattınızı takın tekrar deneyiniz..' });
  const [networkInformation, setNetworkInformation] = useState(navigator.connection || navigator.mozConnection || navigator.webkitConnection);
  const [connectionType, setConnectionType] = useState(navigator.onLine);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [cellular, setIsCellular] = useState(false);

  useEffect(() => {
    if (isOnline !== true) {
      const dialogProps = {
        show: true,
        message: 'İnternet erişiminizde problem var gibi görünüyor. Şebeke bağlantınızı test edebilmemiz için internet erişminiz olması gerekmekte. Lütfen tekrar deneyin',
      };
      setShowDialog(dialogProps);
    } else if (cellular === true) {
      endTest(true, 'PASSED', { isOnline, connectionType });
    } else {
      const dialogProps = {
        show: true,
        message: 'Mobil şebeke ağına bağlı değilsiniz. Cihazınızın şebeke özelliğini test edebilmemiz için mobil ağlara bağlı olmalısınız. Hattınızı takarak veya mobil verinizi açarak bağlantı kurabilirsiniz.',
      };
      setShowDialog(dialogProps);
    }
  }, [cellular]);

  async function checkIsCellular() {
    await Get(ep.ISP_DETAILS).then((r) => {
      if (r.result === true) {
        if (r.objectId === 'mobile') {
          setIsCellular(true);
        }
      }
    });
  }

  function endTest(result, state, resultPayload) {
    endTestForWifi();
    const payload = {
      result: true,
      state,
      isOnline: resultPayload.isOnline,
      connectionType: resultPayload.connectionType,
      time: new Date().getTime(),
    };

    insert('CellularNetworkComponentResult', payload);
    setShowDialog({ show: false, message: '' });
    props.endTest(result, state);
  }

  function endTestForWifi(){
    const payload = {
      result: true,
      state: 'PASSED',
      isOnline: true,
      connectionType: 'wifi',
      time: new Date().getTime(),
    };

    insert('WifiCheckComponentResult', payload);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED', { isOnline, connectionType })}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => checkIsCellular()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrarla
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog.show}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog.show}
        icon="wifi-alert"
      >
        <p>{showDialog.message}</p>
      </Dialog>
    </>
  );
}

export default IOSCellularNetworkComponentAction;
