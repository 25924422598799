import React, { useState } from "react";
import { insert } from "Utils/StorageConnector";

function DiagnosisComplete(props) {
  document.body.style.position = "fixed";
  document.body.style.overflow = "hidden";

  return (
    <div className="multiple-touch-test-container bg-warning-light">
      <div>
        <p className="color-warning screen-touch-action-text">
          Cihaz değerlendirme işleminiz tamamlanmıştır. Sonuçlar kontrol
          edildikten sonra Sms ile bilgilendirileceksiniz. Sorularınız için 0216
          416 44 44 no'lu çağrı merkezimizi arayabilirsiniz.
        </p>
      </div>
    </div>
  );
}

export default DiagnosisComplete;
