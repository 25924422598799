import React from 'react';
import _ from 'lodash';

const rectRenderer = function (icon) {
  const rects = [];

  if (!_.isEmpty(icon.d) && !_.isEmpty(icon.d.rect)) {
    let i = 0;
    icon.d.rect.forEach((r) => {
      rects.push(<rect key={i} {...r} />);
      i += 1;
    });
  }

  return (rects);
};

const pathRenderer = function (icon) {
  const paths = [];

  if (!_.isEmpty(icon.d) && !_.isEmpty(icon.d.path)) {
    let i = 0;
    icon.d.path.forEach((p) => {
      paths.push(<path key={i} {...p} />);
      i += 1;
    });
  }

  return (paths);
};

const polygonRenderer = function (icon) {
  const polygons = [];

  if (!_.isEmpty(icon.d) && !_.isEmpty(icon.d.polygon)) {
    let i = 0;
    icon.d.polygon.forEach((p) => {
      polygons.push(<polygon key={i} {...p} />);
      i += 1;
    });
  }

  return (polygons);
};

const circleRenderer = function (icon) {
  const circles = [];

  if (!_.isEmpty(icon.d) && !_.isEmpty(icon.d.circle)) {
    let i = 0;
    icon.d.circle.forEach((c) => {
      circles.push(<circle key={i} {...c} />);
      i += 1;
    });
  }

  return (circles);
};

const ellipseRenderer = function (icon) {
  const ellipses = [];

  if (!_.isEmpty(icon.d) && !_.isEmpty(icon.d.ellipse)) {
    let i = 0;
    icon.d.ellipse.forEach((e) => {
      ellipses.push(<ellipse key={i} {...e} />);
      i += 1;
    });
  }

  return (ellipses);
};

const SvgContainer = (props) => (
  <svg {...props.icon.attributes}>
    <path className={props.className} {...props.icon.path.attributes}>
      {rectRenderer(props.icon)}
      {pathRenderer(props.icon)}
      {polygonRenderer(props.icon)}
      {circleRenderer(props.icon)}
      {ellipseRenderer(props.icon)}
    </path>
  </svg>
);

export default SvgContainer;
