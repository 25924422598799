import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from '../../Utils/Sleep';

const random = Math.floor(Math.random() * 4);

export default function GravitySensorCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState(true);
  const [sensor, setSensor] = useState();
  const [permission, setPermission] = useState();
  const [counter, setCounter] = useState();

  let isRunning = false;

  useEffect(() => {
    window.addEventListener('userproximity', (event) => {
    });

    window.addEventListener('deviceproximity', (e) => {
    });

    window.addEventListener('lightlevel', (e) => {
    });

    window.addEventListener('devicelight', (e) => {
    });

    permissionRequestForIos13();

    if (isRunning) {
      window.removeEventListener('devicemotion', handleMotion);
      window.removeEventListener('deviceorientation', handleOrientation);
      isRunning = false;
    } else {
      window.addEventListener('devicemotion', handleMotion);
      window.addEventListener('deviceorientation', handleOrientation);
      isRunning = true;
    }
  }, [permission]);

  function handleMotion(event) {
    updateFieldIfNotNull('Accelerometer_gx', event.accelerationIncludingGravity.x);
    updateFieldIfNotNull('Accelerometer_gy', event.accelerationIncludingGravity.y);
    updateFieldIfNotNull('Accelerometer_gz', event.accelerationIncludingGravity.z);

    updateFieldIfNotNull('Accelerometer_x', event.acceleration.x);
    updateFieldIfNotNull('Accelerometer_y', event.acceleration.y);
    updateFieldIfNotNull('Accelerometer_z', event.acceleration.z);

    updateFieldIfNotNull('Accelerometer_i', event.interval, 2);

    updateFieldIfNotNull('Gyroscope_z', event.rotationRate.alpha);
    updateFieldIfNotNull('Gyroscope_x', event.rotationRate.beta);
    updateFieldIfNotNull('Gyroscope_y', event.rotationRate.gamma);
    incrementEventCount();
  }

  function handleOrientation(event) {
    updateFieldIfNotNull('Orientation_a', event.alpha);
    updateFieldIfNotNull('Orientation_b', event.beta);
    updateFieldIfNotNull('Orientation_g', event.gamma);
    incrementEventCount();
  }

  function incrementEventCount() {
    setCounter(counter + 1);
  }

  function updateFieldIfNotNull(fieldName, value, precision = 10) {
    if (fieldName === 'Accelerometer_gx') {
      if (value) {
        endTest(true, 'PASSED');
      }
    } else {
      endTest(true, 'NOT_SUPPPORTED');
    }
  }

  function permissionRequestForIos13() {
    if (DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission();
    }
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('GyroscopeCheckComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => maybeSleep()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrar Dene
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="leak-off"
      >
        <p>Yerçekimi sensörlü ivme ölçer sensörü hareketleri algılanmaya çalışılıyor. Lütfen bekleyin..</p>
      </Dialog>
    </>
  );
}
