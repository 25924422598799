import React, { useState } from 'react';

function DiagnoseStateBuilder(stateColor, stateText, indicator) {
  const textTemplateClassNames = `sub-text color-${stateColor}`;

  return ({
    textTemplate: <span className={textTemplateClassNames}>{stateText}</span>,
    indicatorTemplate: indicator,
  });
}

export default DiagnoseStateBuilder;
