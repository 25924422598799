import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from 'Utils/Sleep';
import Modal from '../Modal/Modal';

function BatteryChargeCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState({ show: true, message: 'Lütfen cihazınızın şarj kablosunu takın' });
  const [showModal, setShowModal] = useState({});
  const [newState, setNewState] = useState();
  const [battery, setBattery] = useState();
  const [isCharging, setIsCharging] = useState(false);

  useEffect(async () => {
    if (battery) {
      setIsCharging(battery.charging);
      battery.addEventListener('chargingchange', () => {
        setIsCharging(battery.charging);
      });
    }

    if (isCharging === true) {
      endTest(true, 'PASSED');
    }

    return () => {
      battery.removeEventListener('chargingchange', () => {
      });
    };
  }, [battery, isCharging]);

  async function checkBattery() {
    const deviceBattery = await navigator.getBattery();
    setBattery(deviceBattery);
    console.log(deviceBattery);
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('BatteryChargeCheckComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => checkBattery()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Taktım
    </button>
  );

  return (
    <>

      <Dialog
        init={showDialog.show}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog.show}
        icon="battery-off"
      >
        <p>{showDialog.message}</p>
      </Dialog>
    </>
  );
}

export default BatteryChargeCheckComponentAction;
