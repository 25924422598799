import React from 'react';

function Modal({
  customClass = '', init = false, backdrop = false, dismiss = true, children,
}) {
  const show = init === true ? 'can-seen' : '';
  let classes = `fs-modal ${show}`;

  if (customClass) {
    classes += ` ${customClass}`;
  }
  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export default Modal;
