import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from 'Hooks/useWindowSize';

const Canvas = (props) => {
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const [canvas, setCanvas] = useState();

  const [lastPoint, setLastPoint] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const [hideButton, setHideButton] = useState('end-test-by-user-button-container');
  const sizes = useWindowSize();
  const [canvasPaintValue, setCanvasPaintValue] = useState();
  const [canvasSizes, setCanvasSizes] = useState({ width: 0, height: 0 });

  function resizeCanvas(canvas) {
    const { width, height } = canvas.getBoundingClientRect();

    if (canvas.width !== width || canvas.height !== height) {
      const { devicePixelRatio: ratio = 1 } = window;
      const context = canvas.getContext('2d');
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      context.scale(ratio, ratio);
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.lineJoin = ctx.lineCap = 'round';
      resizeCanvas(canvasRef.current);
      setContext(ctx);
      setCanvas(canvasRef.current);
    }
  }, [canvasRef]);

  function hideButtonWhilePainting() {
    setHideButton('end-test-by-user-button-container f-hidden');
  }

  function showButtonWhileStopping() {
    setHideButton('end-test-by-user-button-container');
  }

  function readImageData() {
    const imageData = context.getImageData(0, 0, context.canvas.width, context.canvas.height);
    const pixel = imageData.data;

    let alphaPixels = 0;

    for (let i = 3; i < pixel.length; i += 4) {
      if (pixel[i] > 0) alphaPixels++;
    }

    const paintValue = alphaPixels / (context.canvas.width * context.canvas.height);
    const paintOutput = canvas.toDataURL();

    setCanvasPaintValue(paintValue);
    setCanvasSizes({ width: context.canvas.width, height: context.canvas.height });

    if (paintValue <= 0.90) {
      props.dialogOpener({ paintValue, paintOutput });
    } else {
      props.endPaintTest({ paintValue, paintOutput });
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  function handleOnTouchStart(e) {
    const touches = e.changedTouches;
    setLastPoint({ x: touches[0].clientX, y: touches[0].clientY });
    setDrawing(true);
  }

  function handleTouchMove(e) {
    if (!drawing) return;
    const touches = e.changedTouches;
    const currPoint = { x: touches[0].clientX, y: touches[0].clientY };
    setCurrentPoint(currPoint);
    const dist = distanceBetween(lastPoint, currPoint);
    const angle = angleBetween(lastPoint, currPoint);
    hideButtonWhilePainting();

    for (let i = 0; i < dist; i += 5) {
      const x = lastPoint.x + (Math.sin(angle) * i);
      const y = lastPoint.y + (Math.cos(angle) * i);

      const radgrad = context.createRadialGradient(x, y, 10, x, y, 20);

      radgrad.addColorStop(0, '#FF0000');
      radgrad.addColorStop(0.5, 'rgba(255,0,0,1)');
      radgrad.addColorStop(1, 'rgba(255,0,0,0)');

      context.fillStyle = radgrad;
      context.fillRect(x - 20, y - 20, 40, 40);
    }
    setLastPoint(currPoint);
  }

  function handleTouchEnd(e) {
    setDrawing(false);
    showButtonWhileStopping();
  }

  function distanceBetween(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
  }

  function angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
  }

  return (
    <>
      <canvas
        ref={canvasRef}
        onTouchStart={(e) => handleOnTouchStart(e)}
        onTouchMove={(e) => handleTouchMove(e)}
        onTouchEnd={(e) => handleTouchEnd(e)}
        className="full-width"
      />

      <br />
      <div className={hideButton}>

        <button
          onTouchStart={readImageData}
          className="f-btn f-btn-primary"
        >
          Testi Sonlandır
        </button>
      </div>
    </>
  );
};

export default Canvas;
