import React, { useEffect, useState } from "react";
import useTimer from "Hooks/useTimer";
import DiagnosisComplete from "./Screens/Diagnose/DiagnosisComplete";
import { Device } from "./Utils/Device";
import DiagnosisNotSupportedScreen from "./Screens/Diagnose/DiagnosisNotSupportedScreen";
import screenfull from "screenfull";
import ep from "Utils/endpoints";
import Get from "Utils/Get";
import Post from "Utils/Post";
import DiagnoseScreen from "./Screens/Diagnose/DiagnoseScreen";
import DiagnosisExpired from "./Screens/Diagnose/DiagnosisExpired";
import Sleep from "./Utils/Sleep";
import DiagnoseScreenIOS from "./Screens/Diagnose/DiagnoseScreenIOS";
import DiagnosisNotAvailableScreen from "./Screens/Diagnose/DiagnosisNotAvailableScreen";

import { getFromCache, saveToCache } from "./Utils/CacheConnector";
import getQueryVariable from "./Utils/queryParamsGet";
import DiagnosisEmptySplash from "./Screens/Diagnose/DiagnosisEmptySplash";

function App(props) {
  const [startDiagnosis, setStartDiagnosis] = useState(false);
  const [showDiagnosisButton, setShowDiagnosisButton] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [isDiagnoseInProgress, setIsDiagnoseInProgress] = useState(true);
  const [screenSelection, setScreenSelection] = useState();
  const [supportCheck, setSupportCheck] = useState();
  const [diagnosticKey, setDiagnosticKey] = useState();

  const [stopDiagnosis, setStopDiagnosis] = useState();
  const [connectionType, setConnectionType] = useState();
  const [rendered, renderScreen] = useState();
  const [supported, setIsSupported] = useState();

  let timeLeft = useTimer(2);

  useEffect(async () => {
    let idFromUrl = getQueryVariable("uuid");

    const fromCache = await getFromCache();

    if (!fromCache && idFromUrl) {
      // eslint-disable-next-line no-console
      await saveToCache(idFromUrl).then((r) => console.log(r));
      setDiagnosticKey(idFromUrl);
    } else if (fromCache && !idFromUrl) {
      setDiagnosticKey(fromCache);
    } else if (fromCache && idFromUrl) {
      if (fromCache !== idFromUrl) {
        // eslint-disable-next-line no-console
        saveToCache(idFromUrl).then((r) => console.log(r));
        setDiagnosticKey(idFromUrl);
      } else {
        setDiagnosticKey(idFromUrl);
      }
    } else {
      setDiagnosticKey(undefined);
    }

    let supported = await Device.Os().Mobile().Navigator().Version().Collect();

    let cntType = await Get(ep.ISP_DETAILS).then((r) => {
      return r.objectId;
    });

    setConnectionType(cntType);

    if (supported === true) {
      if (diagnosticKey) {
        if (await maybeExpired()) {
          if (isDiagnoseInProgress === true) {
            if (Device.IOS) {
              renderScreen(
                <DiagnoseScreenIOS
                  showDiagnosisButton={showDiagnosisButton}
                  handleStartDiagnosisButton={handleStartDiagnosisButton}
                  showLoadingIndicator={showLoadingIndicator}
                  startDiagnosis={startDiagnosis}
                  finishDiagnosis={finishDiagnosis}
                  connectionType={connectionType}
                />
              );
            } else {
              renderScreen(
                <DiagnoseScreen
                  showDiagnosisButton={showDiagnosisButton}
                  handleStartDiagnosisButton={handleStartDiagnosisButton}
                  showLoadingIndicator={showLoadingIndicator}
                  startDiagnosis={startDiagnosis}
                  finishDiagnosis={finishDiagnosis}
                />
              );
            }
          } else {
            renderScreen(<DiagnosisComplete />);
          }
        } else {
          renderScreen(<DiagnosisExpired />);
        }
      } else {
        if (diagnosticKey === null || diagnosticKey === false) {
          renderScreen(<DiagnosisNotAvailableScreen />);
        } else if (diagnosticKey === undefined) {
          renderScreen(<DiagnosisEmptySplash />);
        }
      }
    } else {
      renderScreen(<DiagnosisNotSupportedScreen meta={supported} />);
    }
  }, [
    showDiagnosisButton,
    showLoadingIndicator,
    isDiagnoseInProgress,
    startDiagnosis,
  ]);

  useEffect(() => {
    if (timeLeft === 0) {
      setShowDiagnosisButton(true);
      setShowLoadingIndicator(false);

      //setStartDiagnosis(true)
    }
  }, [timeLeft]);

  async function maybeExpired() {
    return await Get(ep.IS_EXPIRED(diagnosticKey)).then((response) => {
      return response.result;
    });
  }

  function handleStartDiagnosisButton() {
    setStartDiagnosis(true);
    if (screenfull.isEnabled) {
      //screenfull.request()
    }
  }

  async function finishDiagnosis() {
    let diagnoseResult = localStorage.getItem("diagnosisData");
    let parsedResult = JSON.parse(diagnoseResult);
    let isSuccessAll = [];
    for (const pr of Object.entries(parsedResult)) {
      if (pr[1]["state"] === "PASSED" || pr[1]["state"] === "NOT_SUPPPORTED") {
        isSuccessAll.push("SUCCESS");
      } else {
        isSuccessAll.push("FAILED");
      }
    }
    parsedResult.ScreenBreakComponentResult.paintOutput = "";

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today =
      mm +
      "/" +
      dd +
      "/" +
      yyyy +
      " " +
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      " " +
      new Date().getSeconds();

    const result = {
      diagnoseResult: JSON.stringify(parsedResult),
      diagnoseKey: diagnosticKey,
      isFinished: 1,
      isSuccess: isSuccessAll.indexOf("FAILED") === -1 ? 1 : 0,
      successed: JSON.stringify(isSuccessAll),
      diagnoseEndDate: today,
      diagnoserAppName: "diagnose-pwa",
      diagnoserAppVersion: "1.0.0",
    };
    await Post(ep.DIAGNOSE_RESULT_SET, result).then((r) => {
      setIsDiagnoseInProgress(false);
    });

    await Sleep(2000);
  }

  return (
    <>
      <div className="main-container">
        <div className="screen-container">{rendered}</div>
      </div>
    </>
  );
}

export default App;
