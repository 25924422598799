import React from 'react';
import ComponentList from './ComponentList';

function DiagnoseScreen(props) {
  return (
    <>
      <div className="app-header">
        {props.showDiagnosisButton

                && (
                <div className="diagnosis-button-container">
                  <button
                    onClick={() => props.handleStartDiagnosisButton()}
                    className="f-btn f-btn-info"
                  >
                    Başlat
                  </button>
                </div>
                )}

        {props.showLoadingIndicator
                && <div className="spinx center app-indicator" />}
      </div>
      <div className="diagnose-items" id="diagnose-items">
        <ComponentList startDiagnosis={props.startDiagnosis} finishDiagnosis={props.finishDiagnosis} />
      </div>
    </>
  );
}

export default DiagnoseScreen;
