import React, { useState } from 'react';

function Backdrop({ init = false }) {
  const [canSeen, setCanSeen] = useState(init);

  const canSeenClass = canSeen === true ? ' can-seen' : '';

  const classes = `backdrop${canSeenClass}`;

  return (
    <>
      {canSeen && <div className={classes} />}
    </>
  );
}

export default Backdrop;
