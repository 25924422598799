import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';

function GpsCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [hasLocation, setHasLocation] = useState();
  const [position, setPosition] = useState();
  const [locationErrorMessage, setLocationErrorMessage] = useState();

  useEffect(() => {
    locationRequest(locationSuccess, locationError);
  }, [position]);

  function locationRequest(success, error) {
    navigator.geolocation.getCurrentPosition(success, error);
  }
  function locationSuccess(incomingPosition) {
    setPosition(incomingPosition);
    if (incomingPosition) {
      endTest(true, 'PASSED');
    }
  }

  function locationError(error) {
    setLocationErrorMessage(error.message);
    if (error.code === 1) {
      setShowDialog(true);
    } else {
      endTest(false, 'FAILED');
    }
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('GpsCheckComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => locationRequest()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Devam Et
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="map-marker-off"
      >
        <p>Cihazınızın GPS cihazının çalışıp çalışmadığını kontrol edebilmemiz için lokasyon erişimi izni vermeniz gerekmektedir.</p>
        <p>{locationErrorMessage}</p>
      </Dialog>
    </>
  );
}

export default GpsCheckComponentAction;
