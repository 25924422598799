import React, { useState } from 'react';
import Ikon from '../Ikon/Ikon';
import Backdrop from './Backdrop';

function Dialog({
  init = false, title, negativeButton, positiveButton, backdrop = true, icon, children,
}) {
  const canSeenClass = init === true ? ' can-seen' : '';

  const classes = `dialog${canSeenClass}`;

  let renderBackdrop = backdrop;

  if (init === false) {
    renderBackdrop = false;
  } else if (init === true && backdrop === false) {
    renderBackdrop = false;
  }

  return (
    <>
      <div className={classes}>
        <div className="dialog-content elevate">
          <div className="dialog-header">
            {title}
          </div>
          <div className="dialog-body">
            <Ikon icon={icon} className="fill-primary" width={55} height={55} />
            {children}
          </div>
          <div className="dialog-footer">
            {negativeButton}
            {positiveButton}
            <div className="clearfix" />
          </div>
        </div>
      </div>
      {renderBackdrop
        && <Backdrop init={renderBackdrop} />}
    </>
  );
}

export default Dialog;
