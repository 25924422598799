import React, { useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Modal from '../Modal/Modal';

function ScreenTouchComponentAction(props) {
  const [showModal, setShowModal] = useState(true);
  const [touchStartCheck, setTouchStartCheck] = useState(null);
  const [touchMoveCheck, setTouchMoveCheck] = useState(null);
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };
    insert('ScreenTouchComponenResult', payload);
    document.body.style.position = '';
    document.body.style.overflow = '';
    setShowModal(false);
    props.endTest(result, state);
  }

  function handleOnTouchStart(e) {
    if (e.touches.length >= 1) {
      setTouchStartCheck(true);
    }
  }

  function handleOnTouchMove(e) {
    if (e.touches.length >= 1) {
      setTouchMoveCheck(true);
    }
  }

  function handleOnTouchEnd(e) {
    if (touchStartCheck && touchMoveCheck) {
      endTest(true, 'PASSED');
    }
  }

  function endByUser() {
    endTest(false, 'FAILED');
  }

  return (
    <Modal init={showModal}>
      <div
        className="multiple-touch-test-container bg-warning-light"
        onTouchStart={(e) => handleOnTouchStart(e)}
        onTouchMove={(e) => handleOnTouchMove(e)}
        onTouchEnd={(e) => handleOnTouchEnd(e)}
      >
        <div>
          <p className="color-warning screen-touch-action-text">
            {' '}
            Ekranınızın herhangi bir yerine birden fazla
            parmağınızla dokunarak hareket ettirin
          </p>
          <button
            onClick={() => endByUser()}
            className="f-btn f-btn-danger f-btn-sm"
          >
            Bu Testi Geç
          </button>
        </div>

      </div>
    </Modal>
  );
}

export default ScreenTouchComponentAction;
