import React from 'react';
import { svgDB } from './SvgDB';
import SvgContainer from './SvgContainer';

const Ikon = function (props) {
  const icon = svgDB[props.icon];
  icon.attributes.width = props.width;
  icon.attributes.height = props.height;
  return (
    <SvgContainer icon={icon} className={props.className} />
  );
};

export default Ikon;
