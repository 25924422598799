import React, { useState } from 'react';
import { insert } from 'Utils/StorageConnector';

function DiagnosisExpired(props) {
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';

  return (
    <div
      className="multiple-touch-test-container bg-warning-light"
    >
      <div>
        <p className="color-warning screen-touch-action-text"> Bu linkin kullanım süresi dolmuştur.</p>
      </div>

    </div>
  );
}

export default DiagnosisExpired;
