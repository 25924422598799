import React, { useEffect, useState } from "react";
import { insert } from "Utils/StorageConnector";
import Dialog from "Components/Modal/Dialog";
import Sleep from "Utils/Sleep";

import catGraphic from "Assets/images/cat.png";
import dogGraphic from "Assets/images/dog.png";
import birdGraphic from "Assets/images/bird.png";
import elephantGraphic from "Assets/images/elephant.png";
import lionGraphic from "Assets/images/lion.png";
import cowGraphic from "Assets/images/cow.png";
import Modal from "../Modal/Modal";

import bark from "Assets/sounds/bark.mp3";
import meow from "Assets/sounds/meow.mp3";
import chirp from "Assets/sounds/chirp.mp3";
import snort from "Assets/sounds/snort.mp3";
import roar from "Assets/sounds/roar.mp3";
import moo from "Assets/sounds/moo.mp3";

const cat = {
  audio: meow,
  image: catGraphic,
};
const dog = {
  audio: bark,
  image: dogGraphic,
};

const bird = {
  audio: chirp,
  image: birdGraphic,
};

const elephant = {
  audio: snort,
  image: elephantGraphic,
};
const lion = {
  audio: roar,
  image: lionGraphic,
};
const cow = {
  audio: moo,
  image: cowGraphic,
};
const random = Math.floor(Math.random() * 6);

function SpeakerCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState();
  const [showModal, setShowModal] = useState({});
  const [chosenAnimal, setChosenAnimal] = useState();

  useEffect(async () => {
    if (random === 0) {
      setChosenAnimal(cat);
    } else if (random === 1) {
      setChosenAnimal(dog);
    } else if (random === 2) {
      setChosenAnimal(bird);
    } else if (random === 3) {
      setChosenAnimal(elephant);
    } else if (random === 4) {
      setChosenAnimal(lion);
    } else if (random === 5) {
      setChosenAnimal(cow);
    }

    beep(chosenAnimal);
  }, [chosenAnimal]);

  async function beep(chosenAnimal) {
    setShowDialog(true);
    if (chosenAnimal) {
      maybeSleep(2000).then((r) => {
        const sound = new Audio(chosenAnimal.audio);
        sound.play();
      });
    }
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function soundCheck(animal) {
    if (animal === chosenAnimal) {
      endTest(true, "PASSED");
    } else {
      repeatTest();
    }
  }

  function repeatTest() {
    setShowDialog(false);
    maybeSleep(2000).then((r) => {
      beep(chosenAnimal);
    });
  }
  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert("SpeakerCheckComponentResult", payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, "SKIPPED")}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => repeatTest()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrar Dinle
    </button>
  );

  return (
    <>
      <Modal init={showModal} />

      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="speaker"
      >
        <p>Duyduğunuz ses aşağıdakilerden hangisine aitti?</p>
        <p className="animalPics">
          <span>
            <img src={cat.image} alt="" onTouchStart={() => soundCheck(cat)} />
            <br />
            Kedi
          </span>
          <span>
            <img src={dog.image} alt="" onTouchStart={() => soundCheck(dog)} />
            <br />
            Köpek
          </span>

          <span>
            <img
              src={bird.image}
              alt=""
              onTouchStart={() => soundCheck(bird)}
            />
            <br />
            Kuş
          </span>
          <span>
            <img
              src={elephant.image}
              alt=""
              onTouchStart={() => soundCheck(elephant)}
            />
            <br />
            Fil
          </span>
          <span>
            <img
              src={lion.image}
              alt=""
              onTouchStart={() => soundCheck(lion)}
            />
            <br />
            Aslan
          </span>
          <span>
            <img src={cow.image} alt="" onTouchStart={() => soundCheck(cow)} />
            <br />
            İnek
          </span>
        </p>
      </Dialog>
    </>
  );
}

export default SpeakerCheckComponentAction;
