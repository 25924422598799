import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from '../../Utils/Sleep';

const random = Math.floor(Math.random() * 4);

export default function VibrationCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState(true);
  const [vibrate, setVibrate] = useState();
  const [vibrateCount, setVibrateCount] = useState();
  const [vibrationStatus, setVibrationStatus] = useState({ text: 'Bekliyor', status: false });
  const [guessState, setGuessState] = useState(true);
  const [randomNumberText, setRandomNumberText] = useState();

  useEffect(() => {
    const vibrate = 0;

    if (random === 0) {
      setVibrateCount(100);
      setRandomNumberText('one');
    } else if (random === 1) {
      setVibrateCount([200, 200, 200, 200]);
      setRandomNumberText('two');
    } else if (random === 2) {
      setVibrateCount([200, 200, 200, 200, 200, 200]);
      setRandomNumberText('three');
    } else if (random >= 3) {
      setVibrateCount([200, 200, 200, 200, 200, 200, 200, 200]);
      setRandomNumberText('four');
    }
    sendVibes();
  }, []);

  function sendVibes() {
    maybeSleep(4000).then((r) => {
      setVibrationStatus({ text: 'Gönderiliyor', status: false });

      window.navigator.vibrate(vibrateCount);
      maybeSleep(1000).then((r) => {
        setVibrationStatus({ text: 'Gönderildi', status: true });
      });
    });
  }

  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('VibrationCheckComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  function handleVibrationCount(guess) {
    if (randomNumberText === guess) {
      endTest(true, 'PASSED');
    } else {
      setGuessState(false);
    }
    return true;
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => sendVibes()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrar Dene
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="vibrate"
      >

        {vibrationStatus.status
                && (
                <p>
                  <span className="circle-numbers" onClick={() => handleVibrationCount('one')}>1</span>
                  <span className="circle-numbers" onClick={() => handleVibrationCount('two')}>2</span>
                  <span className="circle-numbers" onClick={() => handleVibrationCount('three')}>3</span>
                  <span className="circle-numbers" onClick={() => handleVibrationCount('four')}>4</span>
                </p>
                )}
        <p>
          Cihazınızın titreşim motorunu test etmek için birazdan cihazınıza titreşim gönderilecek. Titreşimler
          tamamlandıktan sonra aşağıda kaç kez titreşim gönderildiği soracak. Eğer cihazınızın titreşim
          özelliği kapalı ise açmayı unutmayın.
          <br />
          <span className="color-warning">
            Titreşim durumu:
            <span
              className="color-primary"
            >{vibrationStatus.text}
            </span>
          </span>
          {' '}
          <br />
          {!guessState
                    && <span className="color-danger">Yanlış</span>}
        </p>
      </Dialog>
    </>
  );
}
