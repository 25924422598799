import React, { useEffect, useRef, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import Sleep from 'Utils/Sleep';
import useWindowSize from 'Hooks/useWindowSize';
import Modal from '../Modal/Modal';

function CameraBackComponentAction(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [permissionGrant, setPermissionGrant] = useState(undefined);
  const [mediaStream, setMediaStream] = useState(undefined);
  const sizes = useWindowSize();
  const videoRef = useRef(null);
  const [log, setError] = useState();
  const [enume, setEnum] = useState();


  useEffect(async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Not adding `{ audio: true }` since we only want video now
      navigator.mediaDevices.getUserMedia({video: {facingMode: { exact: "user" }}}).then(function (stream) {
        setShowModal(false)
        //videoRef.current.srcObject = stream;
        //videoRef.current.play();

          stream.getTracks().forEach((track) => {
            track.stop();
            setShowModal(false);
            videoRef.current.srcObject = null;
            endTest(true, 'PASSED');
        });
      });
    }
  }, []);

  function maybeGranted() {
    navigator.permissions.query({ name: 'camera', ...'' })
      .then((permission) => {
        setPermissionGrant(permission.state);
      });
  }


  async function maybeSleep(seconds) {
    await Sleep(seconds);
  }

  function endTest(result, state) {
    setMediaStream(undefined);
    const payload = {
      result: true,
      state,
      time: new Date().getTime(),
    };

    insert('CameraBackComponentResult', payload);
    setShowDialog(false);
    props.endTest(result, state);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED')}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      className="f-btn f-btn-primary f-pull-right"
    >
      Devam Et
    </button>
  );

  return (
    <>
      <Modal init={showModal}>
        <div className="full-width">
          <video ref={videoRef}  autoPlay playsInline muted />

        </div>

      </Modal>

      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="camera-off"
      >
        <p>
          Kameranızı test edebilmemiz için izin vermelisiniz. İzni verdikten sonra Devam et butonuna
          basınız.
        </p>
      </Dialog>
    </>
  );
}

export default CameraBackComponentAction;
