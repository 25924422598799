import React, { useState } from 'react';
import Ikon from 'Components/Ikon/Ikon';

function IconIndicator(icon, color) {
  const classNames = `fs-40 fill-${color}`;
  return (
    <Ikon icon={icon} className={classNames} />
  );
}

export default IconIndicator;
