import React, { useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Canvas from 'Components/Canvas/Canvas';
import Modal from '../Modal/Modal';
import Dialog from '../Modal/Dialog';

function ScreenBreakComponentAction(props) {
  const [showModal, setShowModal] = useState(true);
  const [showTopDialog, setShowTopDialog] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [paintValue, setPaintValue] = useState(0);
  const [paintOutput, setPaintOutput] = useState(false);
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';

  function endTest(result, state, resultPayload) {
    const payload = {
      result: true,
      state,
      paintValue: resultPayload.paintValue,
      time: new Date().getTime(),
    };
    document.body.style.position = '';
    document.body.style.overflow = '';
    insert('ScreenBreakComponentResult', payload);
    setShowModal(false);
    setShowDialog(false);
    setShowTopDialog(false);
    props.endTest(result, state);
  }

  function dialogOpener(resultPayload) {
    setPaintValue(resultPayload.paintValue);
    setPaintOutput(resultPayload.paintOutput);

    setShowDialog(true);
  }

  function endPaintTest(resultPayload) {
    setPaintValue(resultPayload.paintValue);
    setPaintOutput(resultPayload.paintOutput);
    if (resultPayload.paintValue <= 0.90) {
      endTest(false, 'FAILED', resultPayload);
    } else {
      endTest(true, 'PASSED', resultPayload);
    }
  }

  const topDialogNegativeButton = (
    <button
      onTouchStart={() => endTest(false, 'SKIPPED', { paintValue, paintOutput })}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Testi Geç
    </button>
  );

  const topdialogPositiveButton = (
    <button
      onTouchStart={() => setShowTopDialog(false)}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tamam
    </button>
  );

  const negativeButton = (
    <button
      onTouchStart={() => endTest(false, 'FAILED', { paintValue, paintOutput })}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Evet
    </button>
  );
  const positiveButton = (
    <button
      onTouchStart={() => setShowDialog(false)}
      className="f-btn f-btn-primary f-pull-right"
    >
      Hayır
    </button>
  );
  return (
    <>
      <Modal init={showModal}>
        <Canvas
          dialogOpener={dialogOpener}
          setPaintValue={setPaintValue}
          endPaintTest={endPaintTest}
        />

      </Modal>

      <Dialog
        init={showTopDialog}
        negativeButton={topDialogNegativeButton}
        positiveButton={topdialogPositiveButton}
        backdrop={showTopDialog}
        icon="cellphone-erase"
      >
        <p>Parmağınızı kullanarak ekranda beyaz en ufak bir nokta kadar kalmayacak şekilde boyayın.</p>
      </Dialog>

      <Dialog
        init={showDialog}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog}
        icon="cellphone-erase"
      >
        <p>Ekranınızı tamamen boyamadığınızı tespit edildi. Gene de Testi bitirmek istiyor musunuz?</p>
      </Dialog>
    </>
  );
}

export default ScreenBreakComponentAction;
