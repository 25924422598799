const CACHE_NAME = '0,,0';
const CACHE_KEY = 'diagnoseKey';
const CACHE_ENDPOINT = '/diagnose-key';

const saveToCache = async (token) => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const responseBody = JSON.stringify({
      [CACHE_KEY]: token,
    });
    const response = new Response(responseBody);
    await cache.put(CACHE_ENDPOINT, response);
  } catch (error) {
  }
};

const getFromCache = async () => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match(CACHE_ENDPOINT);

    if (!response) {
      return null;
    }

    const responseBody = await response.json();
    return responseBody[CACHE_KEY];
  } catch (error) {
    // Gotta catch 'em all
    console.log('getToken error:', { error });
  }
};

export {
  saveToCache,
  getFromCache,
};
