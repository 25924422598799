export const insert = (type, payload) => {
  try {
    const fromJson = localStorage.getItem('diagnosisData');
    const storedData = fromJson !== null ? JSON.parse(fromJson) : null;
    const newData = { [type]: payload };
    const toJson = JSON.stringify({ ...storedData, ...newData });
    localStorage.setItem('diagnosisData', toJson);
  } catch (e) {
  }
};
