import React, { useEffect, useState } from 'react';

function DiagnosisNotSupportedScreen(props) {
  const [message, setMessage] = useState(props.meta.message);
  const [storeLink, setStoreLink] = useState(props.meta.storeLink);
  const [storeImage, setStoreImage] = useState(props.meta.storeImage);

  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';

  useEffect(async () => {

  }, []);

  return (
    <div
      className="multiple-touch-test-container bg-warning-light"
    >
      <div>
        <p className="color-warning screen-touch-action-text">
          {' '}
          {message}
        </p>
        {storeLink
                && <p className="center vendor-image"><a href={storeLink}><img src={storeImage} alt="" /></a></p>}
      </div>

    </div>
  );
}

export default DiagnosisNotSupportedScreen;
