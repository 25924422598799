import React, { useEffect, useState } from 'react';

function DiagnosisNotAvailableScreen() {

  useEffect(async () => {

  }, []);

  return (
    <div
      className="multiple-touch-test-container bg-warning-light"
    >
      <div>
        <p className="color-warning screen-touch-action-text"> Uygulamaya erişim yetkiniz bulunmamaktadır</p>
      </div>

    </div>
  );
}

export default DiagnosisNotAvailableScreen;
