import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';

function CellularNetworkComponentAction(props) {
  const [showDialog, setShowDialog] = useState({});
  const [networkInformation, setNetworkInformation] = useState(navigator.connection || navigator.mozConnection || navigator.webkitConnection);
  const [connectionType, setConnectionType] = useState(networkInformation.effectiveType);
  // const [connectionType, setConnectionType] = useState(navigator.onLine);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const effectiveTypes = ['slow-2g', '2g', '3g', '4g'];

  useEffect(() => {
    if (networkInformation) {
      networkInformation.addEventListener('change', updateConnectionStatus);
    }

    if (isOnline !== true) {
      const dialogProps = {
        show: true,
        message: 'İnternet erişiminizde problem var gibi görünüyor. Şebeke bağlantınızı test edebilmemiz için internet erişminiz olması gerekmekte. Lütfen tekrar deneyin',
      };
      setShowDialog(dialogProps);
    } else if (networkInformation.type !== 'cellular') {
      const dialogProps = {
        show: true,
        message: 'Mobil şebeke ağına bağlı değilsiniz. Cihazınızın şebeke özelliğini test edebilmemiz için mobil ağlara bağlı olmalısınız. Lütfen hattınızı takın tekrar deneyiniz..',
      };
      setShowDialog(dialogProps);
    } else {
      endTest(true, 'PASSED', { isOnline, connectionType });
    }
  }, [connectionType]);

  function updateConnectionStatus() {
    setConnectionType(networkInformation.effectiveType);
  }

  function endTest(result, state, resultPayload) {
    const payload = {
      result: true,
      state,
      isOnline: resultPayload.isOnline,
      connectionType: resultPayload.connectionType,
      time: new Date().getTime(),
    };

    insert('CellularNetworkComponentResult', payload);
    setShowDialog({});
    props.endTest(result, state);
  }

  function repeatTest() {
    setShowDialog({ show: false, message: null });

    if (isOnline !== true) {
      const modalProps = {
        show: true,
        message: 'İnternet erişiminizde problem var gibi görünüyor. Şebeke bağlantınızı test edebilmemiz için internet erişminiz olması gerekmekte. Lütfen tekrar deneyin',
      };
      setShowDialog(modalProps);
    } else if (networkInformation.type && networkInformation.type !== 'cellular') {
      const modalProps = {
        show: true,
        message: 'Mobil şebeke ağına bağlı değilsiniz. Cihazınızın şebeke özelliğini test edebilmemiz için mobil ağlara bağlı olmalısınız. Lütfen hattınızı takın tekrar deneyiniz..',
      };
      setShowDialog(modalProps);
    } else {
      endTest(true, 'PASSED', { isOnline, connectionType });
    }
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED', { isOnline, connectionType })}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => repeatTest()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrarla
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog.show}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog.show}
        icon="wifi-alert"
      >
        <p>{showDialog.message}</p>
      </Dialog>
    </>
  );
}

export default CellularNetworkComponentAction;
