import appStore from 'Assets/images/app-store.png';
import playStore from 'Assets/images/play-store.png';
import React from 'react';

const OS_NOT_SUPPORTED = {
  status: 'OS_NOT_SUPPORTED',
  message: 'Bu uygulama sadece mobil cihazlar için hazırlanmıştır. Diğer cihazlar için uygun değildir.',
  storeLink: null,
  storeImage: null,
};

const DEVICE_NOT_SUPPORTED = {
  status: 'DEVICE_NOT_SUPPORTED',
  message: 'Bu uygulama sadece Android ve IOS Cep telefonları için hazırlanmıştır. Diğer cihazlar için şimdilik uygun değildir.',
  storeLink: null,
  storeImage: null,
};

const NAVIGATOR_IOS_NOT_SUPPORTED = {
  status: 'NAVIGATOR_IOS_NOT_SUPPORTED',
  message: 'Bu uygulama yalnızca Safari tarayıcılarda çalışmak üzere hazırlanmıştır. Lütfen uygulamayı Safari tarayıcısı ile açın.',
  storeLink: null,
  storeImage: null,
};

const NAVIGATOR_ANDROID_NOT_SUPPORTED = {
  status: 'NAVIGATOR_ANDROID_NOT_SUPPORTED',
  message: 'Bu uygulama yalnızca Chrome tarayıcılarda çalışmak üzere hazırlanmıştır. Chrome tarayıcısını indirmek için tıklayınız.',
  storeLink: null,
  storeImage: null,
};

const VERSION_CHROME_NOT_SUPPORTED = {
  status: 'VERSION_CHROME_NOT_SUPPORTED',
  message: 'Bu uygulama yalnızca versiyon 80 üstü mobil Chrome tarayıcılarda çalışmak üzere hazırlanmıştır. Lütfen Play Storedan tarayıcınızı güncelleyerek tekrar giriş yapın.',
  storeLink: null,
  storeImage: null,
};

const VERSION_IOS_NOT_SUPPORTED = {
  status: 'VERSION_IOS_NOT_SUPPORTED',
  message: 'Bu uygulama yalnızca versiyon 13 ve üstü mobil Safari tarayıcılarda çalışmak üzere hazırlanmıştır. Lütfen App store üzerinden tarayıcınızı güncelleyerek tekrar giriş yapın.',
  storeLink: null,
  storeImage: null,
};

const APP_NOT_INSTALLED = {
  status: 'APP_NOT_INSTALLED',
  message: 'Tanılama işleminize başlayabilmek için uygulamayı ana ekrana ekleyin. Yükleme tamamlandıktan sonra bu ekranı kapatıp uygulamalarınız arasından TelTest uygulamasını açın',
  storeLink: null,
  storeImage: null,
};

const APP_INSTALLED = {
  status: 'APP_INSTALLED',
  message: 'Uygulama cihazınıza kuruldu. Lütfen uygulamayı açarak devam edin.',
  storeLink: null,
  storeImage: null,
};

const Device = {
  Android: navigator.userAgent.match(/Android/i),
  BlackBerry: navigator.userAgent.match(/BlackBerry/i),
  IOS: navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: navigator.userAgent.match(/Opera Mini/i),
  WindowsMobile: navigator.userAgent.match(/IEMobile/i),
  Windows: navigator.userAgent.match(/Windows/i),
  OSX: navigator.userAgent.match(/Mac/i),
  Linux: navigator.userAgent.match(/Linux/i),
  State: undefined,
  Result: 'WAITING',
  Browser: null,
  CurrentVersion: null,
  StoreLink: null,
  StoreImage: null,
  Any() {
    return this.Android || this.IOS || this.BlackBerry || this.Opera || this.WindowsMobile;
  },
  AnyMobile() {
    return this.Android || this.IOS;
  },
  ByWho() {
    const ua = navigator.userAgent; let tem;
    let M = ua.match(/(opera|chrome|CriOS|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

    return {
      navigator: M[0],
      version: M[1],
    };
  },
  Os() {
    if (!this.Any()) {
      this.State = OS_NOT_SUPPORTED;
      this.Result = 'OS_NOT_SUPPORTED';
      return this;
    }
    return this;
  },
  Mobile() {
    if (this.Result === 'WAITING') {
      if (!this.AnyMobile()) {
        this.state = DEVICE_NOT_SUPPORTED;
        this.Result = 'DEVICE_NOT_SUPPORTED';
        return this;
      }
      this.Browser = this.ByWho().navigator;
      this.CurrentVersion = this.ByWho().version;
      if (this.Android) {
        this.StoreLink = 'https://play.google.com/store/apps/details?id=com.android.chrome';
        this.StoreImage = playStore;
      } else if (this.IOS) {
        this.StoreLink = 'https://apps.apple.com/tr/app/google-chrome/id535886823';
        this.StoreImage = appStore;
        if (this.ByWho().navigator === 'CriOS') {
          this.Browser = 'Chrome';
        }

        if (this.ByWho().navigator === 'Safari') {
          this.Browser = 'Safari';
        }
      }

      return this;
    }
    return this;
  },
  Navigator() {
    if (this.Result === 'WAITING') {
      if (this.IOS) {
        if (this.Browser !== 'Safari') {
          this.State = NAVIGATOR_IOS_NOT_SUPPORTED;
          this.Result = 'NAVIGATOR_IOS_NOT_SUPPORTED';
          return this;
        }
        return this;
      }

      if (this.Android) {
        if (this.Browser !== 'Chrome') {
          this.State = NAVIGATOR_ANDROID_NOT_SUPPORTED;
          this.Result = 'NAVIGATOR_ANDROID_NOT_SUPPORTED';
          return this;
        }
      }
      return this;
    }
    return this;
  },
  Version() {
    if (this.Result === 'WAITING') {
      if (this.Browser === 'Chrome') {
        if (this.CurrentVersion < 80) {
          this.State = VERSION_CHROME_NOT_SUPPORTED;
          this.Result = 'VERSION_CHROME_NOT_SUPPORTED';
          return this;
        }
      }

      if (this.Browser === 'Safari') {
        if (this.CurrentVersion < 13) {
          this.State = VERSION_IOS_NOT_SUPPORTED;
          this.Result = 'VERSION_IOS_NOT_SUPPORTED';
          return this;
        }
      }
      return this;
    }
    return this;
  }, /**
    HasInstalled:  function () {
        if (this.Result === 'WAITING') {
            let checkInstalled =  navigator.getInstalledRelatedApps().then(_ => {
                return _;
            });

            console.log(checkInstalled)

            if (!checkInstalled.length) {
                this.State = APP_NOT_INSTALLED;
                this.Result = 'APP_NOT_INSTALLED';

                return this;
            } else {
                return this;
            }
        } else {
            return this;
        }

    },
    DidInstalled: function(){
        if (this.Result === 'WAITING') {
            if (!window.matchMedia('(display-mode: standalone)').matches) {
                this.State = APP_INSTALLED;
                this.Result = 'APP_INSTALLED';
                return this;
            }else{
                return this;

            }
        }else{
            return this;
        }
    } * */
  Collect() {
    if (this.Result === 'WAITING') {
      return true;
    }
    return this.State;
  },
};

export { Device };
