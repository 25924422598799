import React, { useEffect, useState } from 'react';
import { insert } from 'Utils/StorageConnector';
import Dialog from 'Components/Modal/Dialog';
import ep from 'Utils/endpoints';
import Get from "../../Utils/Get";

function IOSWifiCheckComponentAction(props) {
  const [showDialog, setShowDialog] = useState({ show: true, message: 'Wifi ağına bağlı değilsiniz. Cihazınızın Wifi özelliğini test edebilmemiz için wifiye bağlı olmalısınız. Lütfen bir wifi ağına bağlandıktan sonra Tekrarla butonuna basın.' });
  const [networkInformation, setNetworkInformation] = useState(navigator.connection || navigator.mozConnection || navigator.webkitConnection);
  const [connectionType, setConnectionType] = useState(navigator.onLine);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [isWifi, setIsWifi] = useState(false);

  useEffect(() => {
    if (isOnline === false) {
      const dialogProps = {
        show: true,
        message: 'İnternet erişiminizde problem var gibi görünüyor. Wifi bağlantınızı test edebilmemiz için internet erişminiz olması gerekmekte. Lütfen tekrar deneyin',
      };
      setShowDialog(dialogProps);
    } else if (isWifi === true) {
      endTest(true, 'PASSED', { isOnline, connectionType });
    } else {
      const dialogProps = {
        show: true,
        message: 'Wifi ağına bağlı değilsiniz. Cihazınızın Wifi özelliğini test edebilmemiz için wifiye bağlı olmalısınız. Lütfen bir wifi ağına bağlandıktan sonra Tekrarla butonuna basın.',
      };
      setShowDialog(dialogProps);
    }
  }, [isWifi]);

  async function checkIsWifi() {
    await Get(ep.ISP_DETAILS).then((r) => {
      if (r.result === true) {
        if (r.objectId === 'wifi') {
          setIsWifi(true);
        }
      }
    });
  }
  function endTest(result, state, resultPayload) {
    endTestForCellular();
    const payload = {
      result: true,
      state,
      isOnline: resultPayload.isOnline,
      connectionType: resultPayload.connectionType,
      time: new Date().getTime(),
    };

    insert('WifiCheckComponentResult', payload);
    setShowDialog({});
    props.endTest(result, state);
  }

  function endTestForCellular(){
    const payload = {
      result: true,
      state: 'PASSED',
      isOnline: true,
      connectionType: 'cellular',
      time: new Date().getTime(),
    };

    insert('CellularNetworkComponentResult', payload);
  }

  const negativeButton = (
    <button
      onClick={() => endTest(false, 'SKIPPED', { isOnline, connectionType })}
      className="f-btn f-btn-primary f-btn-shadow f-pull-left"
    >
      Geç
    </button>
  );
  const positiveButton = (
    <button
      onClick={() => checkIsWifi()}
      className="f-btn f-btn-primary f-pull-right"
    >
      Tekrarla
    </button>
  );

  return (
    <>
      <Dialog
        init={showDialog.show}
        negativeButton={negativeButton}
        positiveButton={positiveButton}
        backdrop={showDialog.show}
        icon="wifi-alert"
      >
        <p>{showDialog.message}</p>
      </Dialog>
    </>
  );
}

export default IOSWifiCheckComponentAction;
